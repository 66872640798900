import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import Nav from 'react-bootstrap/Nav';

import HeaderNavDropdownItem from 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownItem';
import DropdownLanguage from 'gatsby-theme-dettol/src/components/DropdownLanguage';
import { useBV } from 'gatsby-theme-dettol/src/hooks/useBV';
import {
  extractUrlFromMultiPicker,
  getHomePageUrlPrefixes,
} from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import {
  IHeaderNav,
  IMenuItemRendererProps,
} from 'gatsby-theme-dettol/src/components/Header/HeaderNav/models';
import 'gatsby-theme-dettol/src/components/Header/HeaderNav/HeaderNav.scss';
import './HeaderNavOverride.scss';

  const MenuItemRenderer: FC<IMenuItemRendererProps> = ({ navItem, ...restProps }) => {
  const BV = useBV();
  const extractedUrl = extractUrlFromMultiPicker(navItem.url);
  const isGenericReview = extractedUrl.includes('#GenericReview');
  const linkClick = useCallback(() => {
    restProps.handleRedirect();
    isGenericReview && BV.showProductPicker();
  }, []);

  if (navItem.panels) {
    return <HeaderNavDropdownItem {...restProps} navItem={navItem} />;
  }

  return (
    <Link
      onFocus={() => restProps.showMenu(false)}
      to={`${isGenericReview ? '#reviews' : extractedUrl}`}
      className="nav-link"
      activeClassName="active"
      partiallyActive={extractedUrl != '/'}
      onClick={linkClick}
    >
      <span>{navItem.label}</span>
    </Link>
  );
};

const HeaderNav: FC<IHeaderNav> = ({
  menu,
  languageSelector: { title: langSelectorTitle, languages, currentLangCode, redirectBehavior },
  ...restProps
}) => {
  const navRight = menu?.navigation ? [...menu.navigation] : [];
  const navLeft = navRight.splice(0, Math.round(menu?.navigation.length / 2));
  const homePageUrlPrefixes = getHomePageUrlPrefixes(languages);

  return (
    <div className="dt-header__navbar-holder">
      <Nav>
        <div className="dt-header__nav-left">
          {navLeft.map(({ properties }) => (
            <MenuItemRenderer
              key={`HeaderNavDropdownItem-left-${properties.label}`}
              navItem={properties}
              homePageUrlPrefixes={homePageUrlPrefixes}
              {...restProps}
            />
          ))}
        </div>

        <div className="dt-header__nav-right">
          <ul className="dt-header__nav-right-holder">
            {navRight.map(({ properties }) => (
              <li>
                <MenuItemRenderer
                  key={`HeaderNavDropdownItem-right-${properties.label}`}
                  navItem={properties}
                  homePageUrlPrefixes={homePageUrlPrefixes}
                  {...restProps}
                />
              </li>
            ))}
          </ul>
        </div>
      </Nav>

      {languages ? (
        <DropdownLanguage
          title={langSelectorTitle}
          langs={languages}
          currentLangCode={currentLangCode}
          redirectToHome={redirectBehavior}
        />
      ) : null}
    </div>
  );
};

export default HeaderNav;
