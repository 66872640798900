import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import { useLocation } from '@reach/router';

import { preloadFonts } from './preload';
import { ISeo } from './models';
import './Seo.scss';

const Seo: FC<ISeo> = ({
  siteSettings,
  brandSettings,
  description,
  lang,
  keywords,
  title,
  canonicalUrl,
  disableCanonicals = false,
  externalHreflangs,
  url,
  ogImage,
  preloadImage,
}) => {
  const location = useLocation();
  const siteTitle = siteSettings?.siteName || '';
  const titleTemplate = title === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings?.siteLang || siteSettings?.lang;
  const direction = siteSettings?.isRTL === '1' ? 'rtl' : 'ltr';

  let canonicalGeneratedUrl = siteSettings?.siteUrl || '';
  if (siteSettings?.siteUrl) {
    const { siteUrl } = siteSettings;

    canonicalGeneratedUrl = `${
      siteUrl[siteUrl.length - 1] === '/' ? siteUrl.substring(0, siteUrl.length - 1) : siteUrl
    }${url}`;
  }

  const meta = [
    {
      name: 'robots',
      content:
        process.env.GATSBY_ACTIVE_ENV === 'production' ? 'index, follow' : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      property: 'og:url',
      content: location?.origin + location?.pathname,
    },
    ...(ogImage
      ? [
          {
            property: 'og:image',
            content: ogImage,
          },
        ]
      : []),
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
  ];

  return (
    <Helmet htmlAttributes={{ lang: siteLang, dir: direction }} {...{ title, titleTemplate, meta }}>
      {process.env.GATSBY_ACCESSIBILITY_KEY ? (
        <script
          type="text/javascript"
          data-accesskey={process.env.GATSBY_ACCESSIBILITY_KEY}
          src={withPrefix(`AccessibilityScript.js`)}
        />
      ) : null}
      {!disableCanonicals ? (
        <link rel="canonical" href={canonicalUrl || canonicalGeneratedUrl} />
      ) : null}
      {externalHreflangs?.map((link) => {
        return <link rel="alternate" href={link.value} hrefLang={link.key} />;
      })}
      {preloadFonts.map((font) => (
        <link rel="preload" as="font" key={font.href} crossOrigin="anonymous" {...font} />
      ))}
      {preloadImage ? <link rel="preload" as="image" imageSrcSet={preloadImage} /> : null}
    </Helmet>
  );
};

export default Seo;
