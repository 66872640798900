import React, { FC } from 'react';

import articleExtractor from './extractors/article';
import pageExtractor from './extractors/page';
import { IPageSchema, IExtractorsMap } from './models';

const extractorsMap: IExtractorsMap = {
  Article: articleExtractor,
  default: pageExtractor,
};
const DEFAULT_LOGO_FILE_NAME = 'Dettol_new_logo.svg';

const PageSchema: FC<IPageSchema> = ({ siteSettings, brandSettings, type, name, path, data }) => {
  const pageHref = path ? `${siteSettings?.siteUrl}${path}` : siteSettings?.siteUrl || '';

  const brandInformation = {
    ...brandSettings,
    lang: siteSettings?.siteLang || siteSettings?.lang,
    siteName: siteSettings?.siteName,
    siteUrl: siteSettings?.siteUrl,
    schemaLogoFileName: brandSettings?.schemaLogoFileName || DEFAULT_LOGO_FILE_NAME,
    sameAs: [
      ...(brandSettings.youtubeUrl ? [brandSettings.youtubeUrl] : []),
      ...(brandSettings.facebookUrl ? [brandSettings.facebookUrl] : []),
    ],
  };

  const extractor = extractorsMap[type] || extractorsMap.default;
  const jsonLd = pageHref && {
    name: name || siteSettings?.siteName,
    ...{
      '@context': 'http://schema.org',
      '@type': type,
    },
    ...extractor(pageHref, data, brandInformation),
  };
  const structuredData = JSON.stringify(jsonLd);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />;
};

export default PageSchema;
