import React, { FC } from 'react';
import { Link } from 'gatsby';

import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import { getLocationLang } from 'gatsby-theme-dettol/src/utils/browser';
import { TLogoComponent } from 'gatsby-theme-dettol/src/components/common/Logo/models';
import 'gatsby-theme-dettol/src/components/common/Logo/Logo.scss';
import './LogoOverride.scss';

const Logo: FC<TLogoComponent> = ({ brandSettings: { brandName, brandLogo } }) => {
  const pathLang = getLocationLang();
  const to = '/'.concat(pathLang);

  return (
    <strong className="dt-logo">
      <Link to={to}>
        <UmbracoImage image={brandLogo} alt={brandName} />
      </Link>
    </strong>
  );
};

export default Logo;
