import React, { FC } from 'react';

import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';

import { IMobileAppPromoBanner } from './models';

import './MobileAppPromoBannerOverride.scss';

const getRel = (link: PageContent.TLinkSimple): string | undefined =>
  link?.target === '_blank' ? 'noreferrer noopener' : undefined;

const MobileAppPromoBanner: FC<IMobileAppPromoBanner> = ({
  appLogo: [appLogoItem],
  ingress,
  appstoreLinks,
}) => {
  const {
    properties: { link: appLogoLink, image: appLogoImage },
  } = appLogoItem;

  return (
    <div className="dt-mobile-app-promo-banner__wrapper">
      <div className="dt-mobile-app-promo-banner__logo">
        <a
          href={extractUrlFromMultiPicker(appLogoLink)}
          target={appLogoLink[0].target}
          rel={getRel(appLogoLink[0])}
        >
          <UmbracoImage image={appLogoImage} lazyLoad alt={appLogoLink[0].name} />
        </a>
      </div>
      <p className="dt-mobile-app-promo-banner__ingress">{ingress}</p>
      <div className="dt-mobile-app-promo-banner__links">
        {appstoreLinks.map(({ properties: { link, image } }) => {
          const url = extractUrlFromMultiPicker(link);

          return image.url ? (
            <a key={url} href={url} target={link[0].target} rel={getRel(link[0])}>
              <img
                className="dt-mobile-app-promo-banner__appstore-logo"
                src={image.url}
                alt={link[0].name}
                loading="lazy"
                width="140"
                height="40"
              />
            </a>
          ) : (
            <a key={url} href={url} target={link[0].target} rel={getRel(link[0])}>
              <UmbracoImage
                className="dt-mobile-app-promo-banner__appstore-logo"
                image={image}
                lazyLoad
                alt={link[0].name}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default MobileAppPromoBanner;
