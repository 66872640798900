import React, { FC } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import IconCustom from 'gatsby-theme-dettol/src/components/common/IconCustom';
import HeaderNavDropdownMenu from 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownMenu';
import isTouchDevice from 'gatsby-theme-dettol/src/utils/isTouchDevice';

import { IHeaderNavDropdownItem } from 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownItem/models';

import 'gatsby-theme-dettol/src/components/Header/HeaderNavDropdownItem/HeaderNavDropdownItem.scss';

const HeaderNavDropdownItem: FC<IHeaderNavDropdownItem> = ({
  showMenu,
  isMenuShown,
  navItem,
  currentPage,
}) => {
  const dropDownClasses = classNames('dt-header__dropdown', {
    'dt-header__dropdown--hover': isMenuShown,
  });

  const handleMouseEnter = () => {
    if (isTouchDevice()) return;
    showMenu(true);
  };
  const handleMouseLeave = () => {
    if (isTouchDevice()) return;
    showMenu(false);
  };

  return (
    <div
      className={dropDownClasses}
      data-testid="dt-header"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={noop}
      onBlur={noop}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <a
        href="/"
        className={classNames('nav-link', {
          active: currentPage === 'product-page',
        })}
        role="button"
        onClick={(event) => {
          event.preventDefault();
          showMenu(!isMenuShown);
        }}
      >
        <span>{navItem.label}</span>

        <span className="dt-header__dropdown-caret">
          <IconCustom icon="next_arrow" />
        </span>
      </a>

      {navItem.panels && <HeaderNavDropdownMenu panels={navItem.panels} />}
    </div>
  );
};

export default HeaderNavDropdownItem;
